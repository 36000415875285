@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,900);
@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.main-navigation {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;
    height: 5rem;
    padding: 0 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  
  .main-navigation__logo h1 {
    margin: 0;
    font-size: 1.5rem;
    /* color: #dfcefc; */
  }
  
  .main-navigation__items {
    margin-left: 1.5rem;
    
  }
  
  .main-navigation__items ul {
    display: -webkit-flex;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .main-navigation__items li {
    margin: 0 1rem;
    font-family:  Arial;
  }
  
  .main-navigation__items a,
  .main-navigation__items button {
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    border: none;
    font: inherit;
    background: transparent;
    cursor: pointer;
    vertical-align: middle;
    margin: 0;
  }
  
  /* .main-navigation__items a:hover,
  .main-navigation__items a:active,
  .main-navigation__items a.active,
  .main-navigation__items button:hover,
  .main-navigation__items button:active {
    background: #d128ba; */
    /* color: #da1286; */
    /* border-radius: 5px;
  } */
.ant-input {
    border-radius: 25px;
  }


  .div-curve{
    background: linear-gradient(to bottom, #33ccff 27%, #3399ff 62%);
    height:130px;
    width:500px;
    margin-left: 6px;
    border-radius:0 0 50% 50% / 0 0 100% 100%;
    margin-top:0px;
    -webkit-transform: scaleX(1);
            transform: scaleX(1)
  }
 
.auth-form{
    width:20rem;
    max-width: 80%;
    margin:auto;
}

.form-control label,
.form-control input{
    width:100%;
    display:block;
}

.form-control label{
    margin-bottom: 0.5rem;
}

.form-control{
    margin-bottom: 1rem;
}


.form-actions button,
.btn {
  background: #5101d1;
  font: inherit;
  border: 1px solid #5101d1;
  border-radius: 3px;
  padding: 0.25rem 1rem;
  margin-right: 1rem;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.26);
  color: white;
  cursor: pointer;
}

.form-actions button:hover,
.form-actions button:active,
.btn:hover,
.btn:active {
  background: #6219d6;
  border-color: #6219d6;
}

.ant-upload-list-item-info{
  display: none
}

.main-navigation {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;
    background-color:#d0edfb ;
    height: 5rem;
    padding: 0 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    box-sizing: border-box;
  }
  
  .main-navigation__logo h1 {
    margin: 0;
    font-size: 1.5rem;
    color: #dfcefc;
  }
  
  .main-navigation__items {
    margin-left: 1.5rem;
    
  }
  
  .main-navigation__items ul {
    display: -webkit-flex;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .main-navigation__items li {
    margin: 0 1rem;
    font-family:  Arial;
  }
  
  .main-navigation__items a,
  .main-navigation__items button {
    text-decoration: none;
    color: white;
    padding: 0.25rem 0.5rem;
    border: none;
    font: inherit;
    background: transparent;
    cursor: pointer;
    vertical-align: middle;
    margin: 0;
  }
  
  .main-navigation__items a.active,
  .main-navigation__items button:active {
    background: #ed3995; 
    border-radius: 5px;
  }
#components-layout-demo-responsive .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}
.auth-form{
  width:20rem;
  max-width: 80%;
  margin:auto;
}

.form-control label,
.form-control input{
  width:100%;
  display:block;
}

.form-control label{
  margin-bottom: 0.5rem;
}

.form-control{
  margin-bottom: 1rem;
}


.form-actions button,
.btn {
background: #5101d1;
font: inherit;
border: 1px solid #5101d1;
border-radius: 3px;
padding: 0.25rem 1rem;
margin-right: 1rem;
box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.26);
color: white;
cursor: pointer;
}

.form-actions button:hover,
.form-actions button:active,
.btn:hover,
.btn:active {
background: #6219d6;
border-color: #6219d6;
}

.ant-upload-list-item-info{
display: none
}


.grid2x2 {
min-height: 60vh;
width:60%;
display: -webkit-flex;
display: flex;
-webkit-flex-wrap: wrap;
        flex-wrap: wrap;
-webkit-flex-direction: row;
        flex-direction: row;
margin-left: 20%;
}
.grid2x2 > div {
display: -webkit-flex;
display: flex; 
-webkit-flex-basis: calc(50% - 40px); 
        flex-basis: calc(50% - 40px);  
-webkit-justify-content: center;  
        justify-content: center;
-webkit-flex-direction: column;
        flex-direction: column;
}
.grid2x2 > div > div {
display: -webkit-flex;
display: flex;
-webkit-justify-content: center;
        justify-content: center;
-webkit-flex-direction: row;
        flex-direction: row;
}

.box { margin: 20px; }
.box1 { background-color: red; }
.box2 { background-color: orange; }
.box3 { background-color: purple; }
.box4 { background-color: grey; }



/* New code */

/* --------------- Print Order Css ---------------- */


/* Print Table css */
#printme table{
  width: 100%;
}
#printme p{
  font-size: 14px;
  margin: 0 0 0;
  line-height: 1.4285em;
}
#printme .vendor-details p{
  line-height: 2 !important;
}
#printme .title-bold p span{
  font-weight: bold;
}
#printme table>tr>td{
  padding: 0px;
}
#printme table tr td div{
  padding:8px;
  margin:0px 10px ;
}
#printme .print-header{
  background-color: #315dfb;
}
#printme .head{
  color:#FFF;
  padding:5px;
  font-size:20px;
}


/* ----------------------- table ---------------------- */
.auth-form{
  width:20rem;
  max-width: 80%;
  margin:auto;
}

.form-control label,
.form-control input{
  width:100%;
  display:block;
}

.form-control label{
  margin-bottom: 0.5rem;
}

.form-control{
  margin-bottom: 1rem;
}


.form-actions button,
.btn {
background: #5101d1;
font: inherit;
border: 1px solid #5101d1;
border-radius: 3px;
padding: 0.25rem 1rem;
margin-right: 1rem;
box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.26);
color: white;
cursor: pointer;
}

.form-actions button:hover,
.form-actions button:active,
.btn:hover,
.btn:active {
background: #6219d6;
border-color: #6219d6;
}

.ant-upload-list-item-info{
display: none
}


.grid2x2 {
min-height: 60vh;
width:60%;
display: -webkit-flex;
display: flex;
-webkit-flex-wrap: wrap;
        flex-wrap: wrap;
-webkit-flex-direction: row;
        flex-direction: row;
margin-left: 20%;
}
.grid2x2 > div {
display: -webkit-flex;
display: flex; 
-webkit-flex-basis: calc(50% - 40px); 
        flex-basis: calc(50% - 40px);  
-webkit-justify-content: center;  
        justify-content: center;
-webkit-flex-direction: column;
        flex-direction: column;
}
.grid2x2 > div > div {
display: -webkit-flex;
display: flex;
-webkit-justify-content: center;
        justify-content: center;
-webkit-flex-direction: row;
        flex-direction: row;
}

.box { margin: 20px; }
.box1 { background-color: red; }
.box2 { background-color: orange; }
.box3 { background-color: purple; }
.box4 { background-color: grey; }


.adminDashboard_ant-layout-sider-trigger__3akse{
    background-color: rgb(211, 219, 226);
}

.adminDashboard_ant-layout-sider-has-trigger__2cEUN{
    background-color: rgb(211, 219, 226);
}
.gutter-example .ant-row > div {
    border: 0;
  }
  .gutter-box {
  }
/* .skewnow
{
    position: relative;
    width: 100%;
    height:400px;
    background: aquamarine;
}

.skewnow:after
{
    position: absolute;
    width:100%;
    height:100%;
    background: inherit;
    content: '';
    transform-origin: top right;
    transform: skewY(-10deg);
} */

a
{   text-decoration:none;   }
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.outer
{
    width:100%;
    height:100vh;
    
    /* background:url(' no-repeat center center fixed; */
    /* background-size:cover; */
    
}
.inner
{
    width:100%;
    height:100vh;
    background:transparent;
    display:-webkit-flex;
    display:flex;
    text-align:center;
    -webkit-align-items:center;
            align-items:center;
    transition:all 0.3s linear;
    z-index:0;
    border-radius:3px;
    box-shadow: 0 0px 20px rgba(0,0,0,0.29), 0 0px 6px #00000059;
    background-size:cover;
    }
.inner-hover
{
    -webkit-transform-origin: 75% 50%;
            transform-origin: 75% 50%;
    -webkit-transform: scale(0.85,0.85);
            transform: scale(0.85,0.85);

}
.prod-logo
{
    width: 20%;
    height: 100vh;
    position:absolute;
    left:0;right:0;
    z-index:1;
    padding:20px 0 0 20px;
}
.prod-img
{
    width:100px;
    height:100px;
    margin:0px auto;
    border-radius:3px;
    padding:5px;
    transition:background 0.3s linear;
}
.prod-left
{
    width:55%;
    margin:20px 20px 10px 10px;
}
.prod-right
{
    width:45%;
}
.prod-human-img
{
    width:250px;
    height:300px;
}
.prod-head
{
    margin-left:-5px !important;
    text-align:left;
    font-weight:800;
    font-size:80pt;
    color:#6f7576;
    margin:0;
}
.prod-head-sub
{
   font-weight:400;
    font-size:25pt;
    color:#6f7576;
    margin:0 auto;
}
.prod-logo:hover .prod-img
{
    background:#fff;
    box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.09), 0 3px 2px -2px rgba(0, 0, 0, 0.07), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
}
.main-nav {
    float:left;
    transition: all 2s;
}
.main-nav a {
    font-family: 'Raleway', sans-serif;
    text-align: left;
    display: block;
    color: #0f457f;
    font-size:15pt;
    font-weight:600;
}
.nav-li
{
    cursor:pointer;
    line-height:2;
    display: none;
}
.nav-li a {
  position: relative;
}
.nav-li a:after {    
  background: none repeat scroll 0 0 transparent;
  content: "";
  display: block;
  height: 2px;
  left: 0%;
  position: absolute;
  background: #0f457f;
  transition: width 0.4s ease 0s;
  width: 0;
}
.nav-li a:hover:after { 
  width: 100%; 
}

.prod-payflow-img{
    position: relative;
    display: inline;
}
