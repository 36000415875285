.auth-form{
  width:20rem;
  max-width: 80%;
  margin:auto;
}

.form-control label,
.form-control input{
  width:100%;
  display:block;
}

.form-control label{
  margin-bottom: 0.5rem;
}

.form-control{
  margin-bottom: 1rem;
}


.form-actions button,
.btn {
background: #5101d1;
font: inherit;
border: 1px solid #5101d1;
border-radius: 3px;
padding: 0.25rem 1rem;
margin-right: 1rem;
box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.26);
color: white;
cursor: pointer;
}

.form-actions button:hover,
.form-actions button:active,
.btn:hover,
.btn:active {
background: #6219d6;
border-color: #6219d6;
}

.ant-upload-list-item-info{
display: none
}


.grid2x2 {
min-height: 60vh;
width:60%;
display: flex;
flex-wrap: wrap;
flex-direction: row;
margin-left: 20%;
}
.grid2x2 > div {
display: flex; 
flex-basis: calc(50% - 40px);  
justify-content: center;
flex-direction: column;
}
.grid2x2 > div > div {
display: flex;
justify-content: center;
flex-direction: row;
}

.box { margin: 20px; }
.box1 { background-color: red; }
.box2 { background-color: orange; }
.box3 { background-color: purple; }
.box4 { background-color: grey; }



/* New code */

/* --------------- Print Order Css ---------------- */


/* Print Table css */
#printme table{
  width: 100%;
}
#printme p{
  font-size: 14px;
  margin: 0 0 0;
  line-height: 1.4285em;
}
#printme .vendor-details p{
  line-height: 2 !important;
}
#printme .title-bold p span{
  font-weight: bold;
}
#printme table>tr>td{
  padding: 0px;
}
#printme table tr td div{
  padding:8px;
  margin:0px 10px ;
}
#printme .print-header{
  background-color: #315dfb;
}
#printme .head{
  color:#FFF;
  padding:5px;
  font-size:20px;
}


/* ----------------------- table ---------------------- */
.auth-form{
  width:20rem;
  max-width: 80%;
  margin:auto;
}

.form-control label,
.form-control input{
  width:100%;
  display:block;
}

.form-control label{
  margin-bottom: 0.5rem;
}

.form-control{
  margin-bottom: 1rem;
}


.form-actions button,
.btn {
background: #5101d1;
font: inherit;
border: 1px solid #5101d1;
border-radius: 3px;
padding: 0.25rem 1rem;
margin-right: 1rem;
box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.26);
color: white;
cursor: pointer;
}

.form-actions button:hover,
.form-actions button:active,
.btn:hover,
.btn:active {
background: #6219d6;
border-color: #6219d6;
}

.ant-upload-list-item-info{
display: none
}


.grid2x2 {
min-height: 60vh;
width:60%;
display: flex;
flex-wrap: wrap;
flex-direction: row;
margin-left: 20%;
}
.grid2x2 > div {
display: flex; 
flex-basis: calc(50% - 40px);  
justify-content: center;
flex-direction: column;
}
.grid2x2 > div > div {
display: flex;
justify-content: center;
flex-direction: row;
}

.box { margin: 20px; }
.box1 { background-color: red; }
.box2 { background-color: orange; }
.box3 { background-color: purple; }
.box4 { background-color: grey; }

