.main-navigation {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;
    background-color:#d0edfb ;
    height: 5rem;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }
  
  .main-navigation__logo h1 {
    margin: 0;
    font-size: 1.5rem;
    color: #dfcefc;
  }
  
  .main-navigation__items {
    margin-left: 1.5rem;
    
  }
  
  .main-navigation__items ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
  }
  
  .main-navigation__items li {
    margin: 0 1rem;
    font-family:  Arial;
  }
  
  .main-navigation__items a,
  .main-navigation__items button {
    text-decoration: none;
    color: white;
    padding: 0.25rem 0.5rem;
    border: none;
    font: inherit;
    background: transparent;
    cursor: pointer;
    vertical-align: middle;
    margin: 0;
  }
  
  .main-navigation__items a.active,
  .main-navigation__items button:active {
    background: #ed3995; 
    border-radius: 5px;
  }