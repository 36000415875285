.ant-input {
    border-radius: 25px;
  }


  .div-curve{
    background: linear-gradient(to bottom, #33ccff 27%, #3399ff 62%);
    height:130px;
    width:500px;
    margin-left: 6px;
    border-radius:0 0 50% 50% / 0 0 100% 100%;
    margin-top:0px;
    transform: scaleX(1)
  }
 